/*
 *
 * AccountSchemeTemplates reducer
 *
 */
import { fromJS, List, Map } from 'immutable'

import {
  COPY_ACCOUNT_SCHEME_TEMPLATE,
  CREATE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  CREATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  DELETE_ACCOUNT_SCHEME_TEMPLATE,
  DELETE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  DELETE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
  EDIT_ACCOUNT_SCHEME_TEMPLATE,
  GET_ACCOUNT_SCHEME_TEMPLATES,
  GET_ACCOUNT_SCHEME_TEMPLATES_ERROR,
  GET_ACCOUNT_SCHEME_TEMPLATES_SUCCESS,
  TOGGLE_SHOW_ACCOUNT_SCHEME_TEMPLATE_FORM,
  UPDATE_ACCOUNT_SCHEME_TEMPLATE,
  UPDATE_ACCOUNT_SCHEME_TEMPLATE_ERROR,
  UPDATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS,
} from './constants'
import { AccountSchemeTemplateRecord } from 'records'
import { getScope } from 'containers/ReportSchemes/functions'
import {
  COMPANY_SCOPE,
  CUSTOMER_SCOPE,
  SYSTEM_SCOPE,
} from 'containers/App/constants'

const initialState = fromJS({
  scope: new Map(),
  edit: undefined,
  showAccountSchemeTemplateForm: false,
})

function accountSchemeTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_ACCOUNT_SCHEME_TEMPLATE:
    case COPY_ACCOUNT_SCHEME_TEMPLATE:
    case UPDATE_ACCOUNT_SCHEME_TEMPLATE: {
      const { companyCode, customerCode } = action
      const scope = getScope({ companyCode, customerCode })
      return state
        .setIn(['scope', scope, 'loading'], true)
        .setIn(['scope', scope, 'error'], false)
    }

    case CREATE_ACCOUNT_SCHEME_TEMPLATE_ERROR:
    case DELETE_ACCOUNT_SCHEME_TEMPLATE_ERROR:
    case GET_ACCOUNT_SCHEME_TEMPLATES_ERROR:
    case UPDATE_ACCOUNT_SCHEME_TEMPLATE_ERROR: {
      const { companyCode, customerCode } = action
      const scope = getScope({ companyCode, customerCode })
      return state
        .setIn(['scope', scope, 'loading'], false)
        .setIn(['scope', scope, 'error'], action.error)
    }

    case CREATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS:
      const { companyCode, customerCode } = action
      const scope = getScope({ companyCode, customerCode })
      return state
        .set('showAccountSchemeTemplateForm', false)
        .setIn(['scope', scope, 'loading'], false)
        .setIn(
          ['scope', scope, 'accountSchemeTemplates'],
          state
            .getIn(['scope', scope, 'accountSchemeTemplates'], List())
            .push(new AccountSchemeTemplateRecord(action.accountSchemeTemplate))
        )

    case DELETE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS: {
      const { companyCode, customerCode } = action
      const scope = getScope({ companyCode, customerCode })
      return state
        .setIn(['scope', scope, 'loading'], false)
        .setIn(
          ['scope', scope, 'accountSchemeTemplates'],
          state
            .getIn(['scope', scope, 'accountSchemeTemplates'], List())
            .filter(
              (accountSchemeTemplate) => accountSchemeTemplate.id !== action.id
            )
        )
    }

    case EDIT_ACCOUNT_SCHEME_TEMPLATE:
      return state
        .set('edit', action.id)
        .set('showAccountSchemeTemplateForm', false)

    case GET_ACCOUNT_SCHEME_TEMPLATES: {
      const { companyCode, customerCode } = action
      const scope = getScope({ companyCode, customerCode })
      return state
        .setIn(['scope', scope, 'loading'], true)
        .setIn(['scope', scope, 'error'], false)
    }

    case GET_ACCOUNT_SCHEME_TEMPLATES_SUCCESS: {
      const { companyCode, customerCode, accountSchemeTemplates } = action
      const scope = getScope({ companyCode, customerCode })
      const accountSchemeTemplatesGroupedByDefinedInScope = accountSchemeTemplates.reduce(
        (acc, accountSchemeTemplate) => {
          const { definedInScope } = accountSchemeTemplate
          const key =
            definedInScope === COMPANY_SCOPE
              ? companyCode
              : definedInScope === CUSTOMER_SCOPE
              ? customerCode || companyCode.split('-')?.[0]
              : SYSTEM_SCOPE
          if (!acc[key]) {
            acc[key] = []
          }
          accountSchemeTemplate = new AccountSchemeTemplateRecord(
            accountSchemeTemplate
          )
          acc[key].push(accountSchemeTemplate)
          return acc
        },
        {}
      )

      if (
        companyCode &&
        !accountSchemeTemplatesGroupedByDefinedInScope[companyCode]
      ) {
        accountSchemeTemplatesGroupedByDefinedInScope[companyCode] = new List()
      }

      if (
        customerCode &&
        !accountSchemeTemplatesGroupedByDefinedInScope[customerCode]
      ) {
        accountSchemeTemplatesGroupedByDefinedInScope[customerCode] = new List()
      }

      state = state.setIn(['scope', scope, 'loading'], false)
      Object.keys(accountSchemeTemplatesGroupedByDefinedInScope).forEach(
        (key) => {
          if (!state.hasIn(['scope', key, 'accountSchemeTemplates'])) {
            state = state.setIn(
              ['scope', key, 'accountSchemeTemplates'],
              List(accountSchemeTemplatesGroupedByDefinedInScope[key])
            )
          } else {
            state = state.updateIn(
              ['scope', key, 'accountSchemeTemplates'],
              (accountSchemeTemplates) =>
                accountSchemeTemplates.concat(
                  accountSchemeTemplatesGroupedByDefinedInScope[key].filter(
                    (accountSchemeTemplate) =>
                      !accountSchemeTemplates.find(
                        (ast) => ast.id === accountSchemeTemplate.id
                      )
                  )
                )
            )
          }
        }
      )
      return state
    }

    case TOGGLE_SHOW_ACCOUNT_SCHEME_TEMPLATE_FORM:
      return state
        .set('edit', undefined)
        .set(
          'showAccountSchemeTemplateForm',
          !state.get('showAccountSchemeTemplateForm')
        )

    case UPDATE_ACCOUNT_SCHEME_TEMPLATE_SUCCESS: {
      const { companyCode, customerCode, accountSchemeTemplate } = action
      const scope = getScope({ companyCode, customerCode })

      return state
        .set('edit', undefined)
        .setIn(['scope', scope, 'loading'], false)
        .setIn(['scope', scope, 'error'], false)
        .updateIn(
          ['scope', scope, 'accountSchemeTemplates'],
          (accountSchemeTemplates) =>
            accountSchemeTemplates.map((ast) => {
              if (ast.id !== accountSchemeTemplate.id) return ast
              return new AccountSchemeTemplateRecord({
                ...accountSchemeTemplate,
              })
            })
        )
    }
    default:
      return state
  }
}

export default accountSchemeTemplatesReducer
