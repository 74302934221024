/*
 *
 * ReportSchemes reducer
 *
 */

import { fromJS, List, Map } from 'immutable'
import { ReportSchemeRecord } from 'records'
import { pick } from 'lodash'

import {
  CREATE_REPORT_SCHEME,
  CREATE_REPORT_SCHEME_ERROR,
  CREATE_REPORT_SCHEME_SUCCESS,
  COPY_REPORT_SCHEME,
  COPY_REPORT_SCHEME_ERROR,
  COPY_REPORT_SCHEME_SUCCESS,
  DELETE_REPORT_SCHEME,
  DELETE_REPORT_SCHEME_ERROR,
  DELETE_REPORT_SCHEME_SUCCESS,
  GET_REPORT_SCHEMES,
  GET_REPORT_SCHEMES_ERROR,
  GET_REPORT_SCHEMES_SUCCESS,
  TOGGLE_SHOW_CREATE_REPORT_SCHEME,
  UPDATE_REPORT_SCHEME,
  UPDATE_REPORT_SCHEME_ERROR,
  UPDATE_REPORT_SCHEME_SUCCESS,
} from './constants'
import { TOGGLE_PERCENTAGE_TARGET_SUCCESS } from '../ReportScheme/constants'
import { calculateDefinedInScope, getScope } from './functions'
import {
  COMPANY_SCOPE,
  CUSTOMER_SCOPE,
  SYSTEM_SCOPE,
} from 'containers/App/constants'

const initialState = fromJS({
  scope: new Map(),
  showCreateReportScheme: false,
})
const reportSchemesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REPORT_SCHEME:
    case COPY_REPORT_SCHEME:
    case DELETE_REPORT_SCHEME:
    case GET_REPORT_SCHEMES:
    case UPDATE_REPORT_SCHEME: {
      const { companyCode, customerCode } = action
      const scope = getScope({ companyCode, customerCode })
      return state.setIn(['scope', scope, 'loading'], true)
    }

    case CREATE_REPORT_SCHEME_ERROR:
    case COPY_REPORT_SCHEME_ERROR:
    case DELETE_REPORT_SCHEME_ERROR:
    case GET_REPORT_SCHEMES_ERROR:
    case UPDATE_REPORT_SCHEME_ERROR: {
      const { companyCode, customerCode } = action
      const scope = getScope({ companyCode, customerCode })
      return state
        .setIn(['scope', scope, 'loading'], false)
        .setIn(['scope', scope, 'error'], action.error)
    }

    case COPY_REPORT_SCHEME_SUCCESS:
    case CREATE_REPORT_SCHEME_SUCCESS: {
      const { companyCode, customerCode, reportScheme } = action
      const scope = getScope({ companyCode, customerCode })
      return state
        .set('showCreateReportScheme', false)
        .setIn(['scope', scope, 'loading'], false)
        .updateIn(['scope', scope, 'reportSchemes'], (reportSchemes) => {
          let definedInScope = SYSTEM_SCOPE
          if (reportScheme.companies.length > 0) {
            definedInScope = COMPANY_SCOPE
          } else if (reportScheme.customers.length > 0) {
            definedInScope = CUSTOMER_SCOPE
          }

          const newReportScheme = new ReportSchemeRecord({
            definedInScope,
            ...reportScheme,
          })
          if (!reportSchemes) {
            return new List([newReportScheme])
          }
          return new List([...reportSchemes].concat([newReportScheme]))
        })
    }

    case DELETE_REPORT_SCHEME_SUCCESS: {
      const { companyCode, customerCode, id } = action
      const scope = getScope({ companyCode, customerCode })
      return state
        .setIn(['scope', scope, 'loading'], false)
        .updateIn(['scope', scope, 'reportSchemes'], (list) =>
          list.filter((reportScheme) => reportScheme.id !== id)
        )
    }

    case GET_REPORT_SCHEMES_SUCCESS: {
      const { companyCode, customerCode, reportSchemes } = action
      const scope = getScope({ companyCode, customerCode })
      const reportSchemesGroupedByDefinedInScope = reportSchemes.reduce(
        (acc, reportScheme) => {
          const definedInScope = calculateDefinedInScope(reportScheme)
          const key =
            definedInScope === COMPANY_SCOPE
              ? companyCode
              : definedInScope === CUSTOMER_SCOPE
              ? customerCode || companyCode.split('-')?.[0]
              : SYSTEM_SCOPE
          if (!acc[key]) {
            acc[key] = []
          }
          const sourceSystems = reportScheme.sourceSystems.map((src) =>
            pick(src, 'integrationSourceSystemId')
          )
          reportScheme = new ReportSchemeRecord({
            ...reportScheme,
            definedInScope,
            sourceSystems,
          })
          acc[key].push(reportScheme)
          return acc
        },
        {}
      )
      state = state.setIn(['scope', scope, 'loading'], false)
      Object.keys(reportSchemesGroupedByDefinedInScope).forEach((key) => {
        if (!state.hasIn(['scope', key, 'reportSchemes'])) {
          state = state.setIn(
            ['scope', key, 'reportSchemes'],
            List(reportSchemesGroupedByDefinedInScope[key])
          )
        } else {
          const reportSchemes = state.getIn(['scope', key, 'reportSchemes'])
          const newReportSchemes = reportSchemesGroupedByDefinedInScope[key]
          const uniqueReportSchemes = newReportSchemes.filter(
            (newReportScheme) =>
              !reportSchemes.some(
                (reportScheme) => reportScheme.id === newReportScheme.id
              )
          )
          state = state.setIn(
            ['scope', key, 'reportSchemes'],
            new List(reportSchemes.concat(uniqueReportSchemes))
          )
        }
      })
      return state
    }

    case TOGGLE_SHOW_CREATE_REPORT_SCHEME:
      return state.set(
        'showCreateReportScheme',
        !state.get('showCreateReportScheme')
      )

    case UPDATE_REPORT_SCHEME_SUCCESS:
    case TOGGLE_PERCENTAGE_TARGET_SUCCESS: {
      const { companyCode, customerCode } = action
      const updatedReportScheme = action.result || action.reportScheme
      const scope = getScope({ companyCode, customerCode }) // ok
      const schemeList = state.getIn(['scope', scope, 'reportSchemes'])
      return state.setIn(['scope', scope, 'loading'], false).setIn(
        ['scope', scope, 'reportSchemes'],
        schemeList.map((reportScheme) => {
          if (reportScheme.id !== updatedReportScheme.id) return reportScheme
          const definedInScope = calculateDefinedInScope(updatedReportScheme)
          return new ReportSchemeRecord({
            definedInScope,
            ...updatedReportScheme,
          })
        })
      )
    }
    default:
      return state
  }
}

export default reportSchemesReducer
